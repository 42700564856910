// extracted by mini-css-extract-plugin
export var MMPage = "style-module--MMPage--FeXE+";
export var blue = "style-module--blue--thWcF";
export var cta5050 = "style-module--cta5050--AWsbb";
export var ctaImage = "style-module--ctaImage--Lu17K";
export var ctaSingle = "style-module--ctaSingle--qTaN9";
export var ctaWords = "style-module--ctaWords--mcGbo";
export var learnMoreButton = "style-module--learnMoreButton--uNyXW";
export var purple = "style-module--purple--xLMam";
export var stageBox = "style-module--stageBox--1h08K";
export var stageImg = "style-module--stageImg--KEUUR";